import React, { useState , useEffect, useCallback } from 'react';
import { Translate , withLocalize } from "react-localize-redux";

import bgLogin from "../../../assets/images/bg-login.png";
import logo from "../../../assets/images/logo-login.png";
import mail from "../../../assets/images/icons/mail.svg";
import lock from "../../../assets/images/icons/lock.svg";

import { useGlobalContext } from '../../context/GlobalContext';
import { loginAction , forgotPasswordAction, getUserAction } from '../../../actions/authActions';
import { validateEmail } from '../../../utils/utils';
import { ERROR_AUTH } from '../../../actions/types';

import styles from "./Login.module.scss";
import history from '../../../utils/history';

function Login({ setActivePage, translate }) {

  const [ context, dispatch ] = useGlobalContext();
  const { auth } = context;

  const [ email , setEmail ] = useState();
  const [ password , setPassword ] = useState();
  const [ error , setError] = useState();
  const [ message , setMessage ] = useState();

  const [ hidePassword , setHidePassword ] = useState(true);
  

  const getUser = useCallback(() => {
    getUserAction(dispatch)
  },[dispatch]);

  useEffect(() => {
    setActivePage("login");
    setError();
    setMessage();
    dispatch({
      type : ERROR_AUTH,
      payload : {}
    });
    return () => {
      setError();
      setMessage();
    }
  // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if(context.auth.isAuth) {
      getUser();
      history.push("/");
    }
  }, [context.auth.isAuth, getUser]);


  useEffect(()=> {
    setMessage();
    if (auth?.error?.status === 422) {
      setError(translate("login.errors.forgot"))
    }
    if (auth?.error === 401) {
      setError(translate("login.errors.401"))
    }
  }, [auth.error, translate])

  useEffect(()=> {
    if (auth?.message.forgot) {
      setError();
      setMessage(translate("login.message.forgot"))
    }
  }, [auth.message.forgot, translate])

  function submitLogin() {
    setMessage();
    if(!password) return setError(translate("login.errors.password"))
    if (validateEmail(email)) {
      setError();
      dispatch({
        type : ERROR_AUTH,
        payload : {}
      });
      loginAction(dispatch, {
        "email": email,
        "password": password
      });
    } else {
      setError(translate("login.errors.email"));
    }
  };

  function forgotPassword() {
    if (validateEmail(email)) {
      setError();
      forgotPasswordAction(dispatch ,{ "email": email })
    } else {
      setError(translate("login.errors.email"));
    }
  }

  function pressKey(e) {
    if(e.key === 'Enter') submitLogin();
  }
 
  return (
    <div className={styles.container} onKeyPress={(e) => pressKey(e)}>

      <div className={`${styles.col} ${styles.img}`}>
        <img src={bgLogin} alt="bg-login"/>
      </div>

      <div className={`${styles.col} ${styles.login}`}>

        <div className={styles.content}>
          <img src={logo} alt="logo leclerc" />
          <h2>Outils de gestion de SALONS</h2>
          <div className={styles.input}>
            <input
              type="email"
              placeholder={translate("login.placeholder.email")}
              onChange={(e) => setEmail(e.target.value)}
              />
            <img src={mail} className={styles.email} alt="mail"/>
          </div>
          <div className={styles.input}>
            <input
              type={hidePassword ? "password" : "text"}
              placeholder={translate("login.placeholder.password")}
              onChange={(e) => setPassword(e.target.value)}
              />
            <img src={lock} alt="lock" onClick={() => setHidePassword(!hidePassword)} />
          </div>
          <button className={styles.connection} onClick={() => submitLogin()}><Translate id="login.login"/></button>
          <div className={styles.message}>
            <span className="text-error">{error}</span>
            <span className="text-success">{message}</span>
          </div>
          <button className={styles.forgot}  onClick={() => forgotPassword() }><Translate id="login.forgot"/></button>
        </div>
      </div>

    </div>
  );
};

export default withLocalize(Login);