import {
  GET_ORDERS_LIST,
  ERROR_ORDERS,
  SCROLL_ON_ORDERS,
  GET_ORDER,
  GET_PRODUCTS_LIST,
  MESSAGE_ORDERS,
  EXPORT_ORDERS,
  VALIDATE_ORDER,
} from "../actions/types"

const initialState = {
  ordersList: [],
  productsList: [],
  order: {},
  error: {},
  scrollOrders: 0,
  message: {},
  products: [],
  exportList : null
};

export default function ordersReducer(state = initialState, action) {
  switch (action.type) {
    case MESSAGE_ORDERS:
      return {...state, message : action.payload.message }
    case EXPORT_ORDERS:
      return {...state, exportList : action.payload}
    case VALIDATE_ORDER:
      return {...state, order : {...state.order , status: "confirmed" } , message: action.payload.message }
    case GET_ORDER:
      return {...state, order : action.payload}
    case GET_PRODUCTS_LIST:
      return {...state, productsList : action.payload}
    case GET_ORDERS_LIST:
      return {...state, ordersList : action.payload}
    case ERROR_ORDERS:
        return {...state, error: action.payload}
    case SCROLL_ON_ORDERS:
      return {...state, scrollOrders: action.payload}
    default:
      return {...state};
  }
}
