import React , { useEffect , useCallback  , useState } from 'react';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import { store } from 'react-notifications-component';


import { useGlobalContext } from '../../context/GlobalContext';
import { getProviderAction , updateProviderAction , getEditListsAction } from '../../../actions/providersActions';

import Company from '../../Partials/Company';
import Contacts from '../../Partials/Contacts';
import Brands from '../../Partials/Brands';
import Showroom from './Providers_Partials/Showroom';

import providerIcon  from "../../../assets/images/icons/provider_icon.svg";

export default function ProviderView({ setActivePage , match}) {
  const [ context , dispatch ] = useGlobalContext();
  const [ editNote , setEditNote ] = useState(false);
  const [ note , setNote ] = useState('');

  const provider = context?.providers?.provider;
  const daysOptions = context.providers?.lists?.days;

  const getProvider = useCallback(() => {
    getProviderAction( dispatch, match.params.id);
  }, [dispatch, match.params.id]);

  const getEditLists = useCallback(() => {
    getEditListsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getProvider();
    setActivePage("providers")
    getEditLists();
  }, [getProvider, getEditLists, setActivePage]);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  function cancelNote() {
    setEditNote(!editNote);
    setNote('');
  }

  function updateNote() {
    const providerUpdated = {
      id: provider.company._id,
      note: note,
    }
    setEditNote(!editNote);
    updateProviderAction(dispatch, providerUpdated);
  }

  useEffect(() => {
    if (context.providers.provider && context.providers.provider.company && context.providers.provider.company.note) {
      setNote(context.providers.provider.company.note)
    }
  }, [context.providers.provider]);

  useEffect(() => {
    if (context.providers.message === "Company successfully updated!") {
      successUpdate();    }

    if (context.providers.message === "company reset ok") {
      successReset();
    }

  });

  function successUpdate() {
    getProviderAction(dispatch , match.params.id);
    store.addNotification({
      message: "Entreprise mise à jour avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : 'MESSAGE_PROVIDER',
      payload : {}
    });
    // getProvider();
  }

  function successReset() {
    store.addNotification({
      message: "Entreprise réinitialisée avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : 'MESSAGE_PROVIDER',
      payload : {}
    });
    getProvider();
  }

  function renderNote() {
    return (
      <div className="col-1">
        <div className="title">
          <h2><Translate id="company.notes"/></h2>
          {!editNote ? 
            <button className="btn-edit" style={{ width : 100}}
              onClick={()=> setEditNote(!editNote)}
            >
              <Translate id="btn.edit"/>
            </button>
            :
            null
          }
        </div>
        {editNote ? 
          <div className="card notes">
            <textarea autoFocus value={note} onChange={(e) => setNote(e.target.value)}/>
            <button className="btn-edit" style={{ margin: 5, width : 100}}
              onClick={()=> cancelNote()}
              >
              <Translate id="btn.cancel"/>
            </button>
            <button className="btn-edit success" style={{ margin: 5,  width : 100}}
              onClick={()=> updateNote()}
              >
              <Translate id="btn.edit"/>
            </button>
          </div>
          :
          <>
          {note ? 
            <div className="card notes">
              <p>{note}</p>
            </div>
            :
            null
          }
          </>
        }
      </div>
    )
  }
  return (
    <div className="page-container">
      {provider?.company &&
        <div>
          <header className="page-header">
            <div className="icon">
              <img src={providerIcon} alt="icon-provider"/>
            </div>
            <h1>{provider.company.name}</h1>
          </header>
          <main className="page-content">
            <Link to={"/providers"} className="btn-edit mb-10">
              <button className="btn-edit" style={{ marginBottom: 10, width : 100}}><Translate id="btn.back"/></button>
            </Link>

            <div className="row col-2">
              <div className="col-2">
                <Company
                  company={provider.company}
                  dispatch={dispatch}
                  contacts={provider.users}
                  root="provider" />
              </div>

              <div className="col-2">
                <Showroom showroom={provider?.showrooms[0]} activePage={"providers"} companyId={provider?.company?._id}/>
              </div>

            </div>

            <div className="row col-2">
              <div className="col-2">
                <Contacts
                  contacts={provider.users}
                  daysOptions={daysOptions}
                  companyId={provider.company._id} 
                  root="provider" />
              </div>

              {provider.brands.length > 0  ?
                <div className="col-2">
                  <Brands
                    brands={provider.brands}
                    companyId={provider.company._id} 
                    root="provider" />
                </div>
                :
                <div className="row col-2">
                  {renderNote()}
                </div>
              }
            </div>

            {provider.brands.length > 0  &&
              <div className="row col-2">
                {renderNote()}
              </div>
            }
          </main>
        </div>
      }
    </div>
  )
}